import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import 'bootstrap/dist/css/bootstrap.min.css';
import KeycloakUserService from './keycloak/KeycloakUserService';


const root = ReactDOM.createRoot(document.getElementById('root'));

const renderApp= ()=>{root.render(<App />)};
KeycloakUserService.initKeycloak(renderApp);


