import Keycloak from "keycloak-js";
import { keyCloakConfig } from "../config";

const _kc = new Keycloak(keyCloakConfig);
/**
 * Initializes Keycloak instance and calls the provided callback function if successfully authenticated.
 * @param onAuthenticatedCallback
 */

const initKeycloak = (onAuthenticatedCallback) => {
  _kc
    .init({
      onLoad: "login-required",
      checkLoginIframe: false,
      // silentCheckSsoRedirectUri: window.location.origin + "/silent-check-sso.html",
      pkceMethod: "S256",
    })
    .then((authenticated) => {
      onAuthenticatedCallback();
      localStorage.setItem(
        "features",
        JSON.stringify([
          {
            featureId: "Q&A engine",
            state: "abled",
          },
        ])
      );
    })
    .catch(console.error);
};

// Do Keycloak login
const doLogin = _kc.login;

// Do Keycloak logout
const doLogout = _kc.logout;

// Get Keycloak user access token
const getToken = () => _kc.token;

// Check if the user is logged in
const isLoggedIn = () => !!_kc.token;

// Update the Keycloak user access token
const updateToken = (successCallback) =>
  _kc.updateToken(5).then(successCallback).catch(doLogin);

// Get username
const getUsername = () => _kc.tokenParsed?.preferred_username;

// Check if user has a certain role
const hasRole = (roles) => roles.some((role) => _kc.hasRealmRole(role));

// Get the user roles
const getRoles = () => _kc.tokenParsed?.realm_access.roles;
//get client roles
const getClientRoles = () => _kc.tokenParsed?.client_roles;
// Get the user ID
const getUserID = () => _kc.tokenParsed?.sub;

// Check if the user is authenticated
const isAuthenticated = () => _kc.authenticated;

const isTokenExpired = () =>
  _kc.tokenParsed?.exp < Math.round(new Date().getTime() / 1000);

const KeycloakUserService = {
  initKeycloak,
  doLogin,
  doLogout,
  isLoggedIn,
  getToken,
  updateToken,
  getUsername,
  hasRole,
  getRoles,
  getUserID,
  getClientRoles,
  isAuthenticated,
  isTokenExpired,
};

export default KeycloakUserService;
